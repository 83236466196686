<script setup lang="ts">
import { watch } from 'vue';
import { useNotificationManager } from '../shared/composables/notification/use-notification-manager';
import LineNotification from '../shared/components/notifications/line-notification.vue';

// definePageMeta({
//   middleware: 'unauthenticated',
// });

const {
  notificationAnimationDuration,
  isNotificationOpened,
  currentNotification,
} = useNotificationManager();

watch(isNotificationOpened, () => {
  console.log('isNotificationOpened', currentNotification.value);
});
</script>

<template>
  <LineNotification
    class="z-1000"
    :variant="currentNotification?.type"
    :message="currentNotification?.message || ''"
    :opened="isNotificationOpened"
    :animation-duration="notificationAnimationDuration"
  />
  <slot />
</template>
